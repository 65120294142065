import type { TFunction } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { CART_BUTTON_COMPONENT_IDS } from 'root/appConsts/blocksIds';
import { PANEL_IDS } from 'root/appConsts/consts';
import { CART_BUTTON_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { SPECS } from 'root/appConsts/experiments';
import { KB_LINKS } from 'root/appConsts/kb-links';
import { configureWidgetDesign, disableElementsSelection } from 'root/editor/editor.utils';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, _, flowAPI) => {
  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: false,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  const { translations, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const isCartButtonSettingsEnabled = experiments.enabled(SPECS.cartButtonSettings);

  widgetBuilder.set({
    displayName: t('cart-button.widget.title'),
    nickname: 'cartButton',
  });

  widgetBuilder
    .gfpp()
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: KB_LINKS.CART_BUTTON_HELP.id })
    .set('add', { behavior: 'HIDE' })
    .set('settings', { behavior: 'HIDE' });

  if (isCartButtonSettingsEnabled) {
    widgetBuilder.gfpp('desktop').set('mainAction1', {
      label: t('settings-panel.cart-button.title'),
      actionId: PANEL_IDS.cartButtonSettings,
    });
  } else {
    widgetBuilder.gfpp('desktop').set('mainAction1', {
      label: t('design-gfpp.cart-button.design'),
      actionType: 'design',
      actionId: '',
    });
  }

  widgetBuilder.gfpp('mobile').set('mainAction1', {
    label: t('design-gfpp.cart-button.design'),
    actionType: 'design',
    actionId: '',
  });

  disableElementsSelection(widgetBuilder, [
    CART_BUTTON_COMPONENT_IDS.container,
    CART_BUTTON_COMPONENT_IDS.viewCartBox,
    CART_BUTTON_COMPONENT_IDS.divider,
    CART_BUTTON_COMPONENT_IDS.button,
  ]);

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-gfpp.cart-button.designpanel.header'),
    tabs: CART_BUTTON_DESIGN_PANEL_TABS,
    helpId: KB_LINKS.CART_BUTTON_DESIGN_PANEL.id,
    t,
  });
};
